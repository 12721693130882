/*
Template Name: Material Pro Admin
Author: Themedesigner
Email: niravjoshi87@gmail.com
File: scss
*/
/**
 * Table Of Content
 *
 * 	1. Globals
 *	2. Headers
 *	3. Navigations
 *	4. Banners
 *	5. Footers
 *	6. app
 *	7. Widgets
 *	8. Custom Templates
 */

@import "variable";

@import "icons/themify-icons/themify-icons.css";
@import "icons/font-awesome/css/font-awesome.css";
@import "icons/flag-icon-css/flag-icon.min.css";
@import "spinner";
@import "app";
@import "header";
@import "widgets";

@import "horizontal.scss";

@import "pages";
@import "sidebar";
@import "dark-sidebar";
@import "responsive";
@import "rtl";

// Red Theme

.danger {
  @import "colors/red";
  @include angular-material-theme($red-app-theme);
}

// Blue Theme

.blue {
  @import "colors/blue";
  @include angular-material-theme($blue-app-theme);
}

// Green Theme

.green {
  @import "colors/green";
  @include angular-material-theme($green-app-theme);
}

// Dark Theme

.dark {
	.mat-drawer-container {
		color: #8999a0 !important;
	}
  	@import "colors/dark";
  	@include angular-material-theme($dark-theme);
}

$custom-typography: mat-typography-config(
  $font-family: "Poppins",
);
@include mat-core($custom-typography);


// Mat-table Wrap
.table-rasponsive .mat-cell {
  white-space: unset !important;
}

.btn-color {
  background-color: #0A68F1 !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb{
  background-color: #0A68F1 !important;
}

.mat-radio-outer-circle{
  border-color: black;
  // border-color: #0A68F1 !important;
  opacity: 0.5;
}

.mat-slider-thumb-label {
  transform: rotate(45deg) !important;
  border-radius: 50% 50% 0 !important;
  background-color: #0A68F1 !important;
}

.mat-slider-thumb {
  transform: scale(0) !important;
}

.mat-slider-thumb-label-text {
  opacity: 1 !important;
  font-size: 8px !important;
}

.primary-color {
  color: #0A68F1 !important;
}

.mat-checkbox-layout .mat-checkbox-label{
color: unset;
}

.saveIcon {
  background: #0A68F1;
  color: white;
  font-size: 13px;
  border-radius: 4px;
}

.mat-snack-bar-container {
  margin:85px 24px !important;
}

#snav .mat-nav-list .mat-list-item a {
  color : black !important;
}

#snav .mat-nav-list .mat-list-item.selected>.mat-list-item-content>a {
  color: #fff !important;
}

@keyframes blink {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}

.blinking-icon {
  animation: blink 1s infinite;
}

.blod-text{
  font-weight: 500;
}

.error-icon {
  color: #ff000087;
}

.popup_success_multiproposition{
  fill : #0A68F1 !important ;
}
