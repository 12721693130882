/* You can add global styles to this file, and also import other style files */
// .mat-form-field-appearance-outline .mat-form-field-infix {
//     padding: 0.4em 0 !important;
// }

.dropdown-btn {
    border: 1px solid #e0e0e0 !important;
    padding: 5px 12px !important;

    span.ng-star-inserted {
        color: #8d8d8d !important;
        font-size: 16px;
    }
}

.dropdown-multiselect__caret {
    line-height: 20px !important;
}

.mat-form-field-infix {
    border-top: 0.5em solid transparent !important;
}

::ng-deep .custom-style {
    background-color: brown;
    color: white;
}

.my-spinner circle{
    stroke:#fff !important;
  }
  