/*
Template Name: Material Pro Admin
Author: Wrappixel
Email: niravjoshi87@gmail.com
File: scss
*/

/*******************
 Topbar
*******************/
mat-toolbar {
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.5);

  &.topbar {
    padding: 0px 15px;
  }
  .navbar-header {
    text-align: center;
    .navbar-brand {
      display: flex;
      line-height: 18px;

      align-items: center;
      .dark-logo {
        display: none;
      }
      b {
        margin-right: 5px;
      }
      img {
      }
    }
  }

  .app-search {
    display: none;
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0;
    z-index: 99;
    position: absolute;
    .form-control {
      line-height: 42px;
    }
    .cl-srh-btn {
      position: absolute;
      top: 12px;
      right: 15px;
      font-size: 15px;
      color: $bodytext;
      cursor: pointer;
    }
  }
  .profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }
  .app-search.show-search {
    display: block;
  }
}
/*******************
 Topbar
*******************/
.topbar {
  position: relative;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

/*******************
Notify
*******************/

.notify {
  position: relative;
  top: -19px;
  right: 0;
}

.heartbit {
  position: absolute;
  top: -22px;
  right: -4px;
  height: 25px;
  width: 25px;
  z-index: 10;
  border: 5px solid $danger;
  border-radius: 70px;
  -moz-animation: heartbit 1s ease-out;
  animation: heartbit 1s ease-out;
  -moz-animation-iteration-count: infinite;
  -o-animation: heartbit 1s ease-out;
  -o-animation-iteration-count: infinite;
  -webkit-animation: heartbit 1s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-moz-keyframes heartbit {
  0% {
    -moz-transform: scale(0);
    opacity: 0;
  }
  25% {
    -moz-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -moz-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -moz-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -moz-transform: scale(1);
    opacity: 0;
  }
}

@keyframes heartbit {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  25% {
    transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

@-webkit-keyframes heartbit {
  0% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0.1);
    opacity: 0.1;
  }
  50% {
    -webkit-transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
    opacity: 0;
  }
}

@media (max-width: 374px) {
  .srh-btn {
    display: none !important;
  }
}
